import { getDefaultErrorMessage } from '~/libs/helpers';

class CustomError extends Error {
    productionMessage: string = getDefaultErrorMessage();

    constructor(message?: string) {
        super(message);

        this.name = 'CustomError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export default CustomError;
