import 'core-js/stable';

import { SERVER_HOST } from '~/constants/hosts';
import registerIframe from '~/externals/register/registerIframe';
import detectBrowser from '~/libs/detectBrowser';
import errorHandler from '~/libs/errorHandler';

const allowBrowser = ['edge', 'chrome', 'opera', 'whale'];

try {
    const initializeRegister = () => {
        const support = detectBrowser();
        if (!support || !allowBrowser.includes(support.name)) {
            return;
        }

        const currentHref = window?.location?.href || '';
        if (currentHref.startsWith(SERVER_HOST)) {
            return;
        }

        // 알림 서비스 iframe 등록
        registerIframe();
    };

    // register 실행
    const loader = (fn: () => unknown) => {
        if (document.readyState === 'complete') {
            fn();
            return;
        }

        window.addEventListener('DOMContentLoaded', () => {
            fn();
        });
    };

    loader(initializeRegister);
} catch (error) {
    errorHandler(error, 'error occurred in register');
}
