// @afreecatv/domains module constant
import { hostGenerator } from '@afreecatv/domains';
import { cookie } from '@afreecatv/domains';

enum NodeEnvType {
    PRODUCTION = 'production'
}

const globalHosts = hostGenerator({
    environment: NodeEnvType.PRODUCTION,
    currentProtocol: 'https:',
    hostname: ''
});

const {
    RES_AFREECATV,
    WWW_AFREECATV,
    STATIC_AFREECATV,
    LIVE_AFREECATV,
    BJ_AFREECATV,
    LOGIN_AFREECATV,
    MEMBER_AFREECATV,
    NOTI_AFREECATV
} = globalHosts;

const { TICKET_COOKIE_NAME } = cookie;

export const SERVER_HOST = NOTI_AFREECATV;

export const COOKIE_TICKET_NAME = cookie.TICKET_COOKIE_NAME;

// resource hosts
export const RES_HOST = RES_AFREECATV;
export const WWW_HOST = WWW_AFREECATV;
export const STATIC_HOST = STATIC_AFREECATV;

// service hosts
export const LIVE_HOST = LIVE_AFREECATV;
export const BJ_HOST = BJ_AFREECATV;

// login hosts
export const LOGIN_HOST = LOGIN_AFREECATV;
export const MEMBER_HOST = MEMBER_AFREECATV;
