import { SERVER_HOST } from '~/constants/hosts';

let iframe: HTMLIFrameElement | null = null;

/**
 * 알림 서비스 iframe 제공
 *
 * @returns {HTMLIFrameElement} 알림 서비스 iframe
 */
const getNotificationIframe = (): HTMLIFrameElement => {
    // 이미 등록된 iframe 이 있을 경우 해당 iframe 전달
    if (iframe) return iframe;

    // 신규 iframe 생성
    iframe = document.createElement('iframe');
    iframe.src = SERVER_HOST;
    iframe.style.display = 'none';

    return iframe;
};

export default getNotificationIframe;
