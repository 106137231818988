import CustomError from '~/errors/CustomError';
import getNotificationIframe from '~/libs/fcm/browser/getNotificationIframe';

/**
 * 알림 서비스 iframe 추가
 *
 * @throws {Error} 에러 발생
 */
const registerIframe = () => {
    const body = document.querySelector('body');
    if (!body) throw new CustomError('undefined body document object.');

    // body 에 설정한 iframe 추가
    const iframe = getNotificationIframe();
    body.appendChild(iframe);
};

export default registerIframe;
