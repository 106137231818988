import CustomError from '~/errors/CustomError';

class Warning extends CustomError {
    constructor(message?: string) {
        super(message);

        this.name = 'Warning';
    }
}

export default Warning;
