import Warning from '~/errors/Warning';

interface ErrorResponseData {
    RESULT: number;
    MESSAGE: string;
}

class HTTPError extends Warning {
    status: number;
    data: ErrorResponseData;

    constructor(status: number, data: ErrorResponseData) {
        super(data.MESSAGE);
        this.status = status;
        this.data = data;
        this.productionMessage =
            '통신 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.';

        this.name = 'HTTPError';
    }
}

export default HTTPError;
