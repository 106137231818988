import { Browser, detect, OperatingSystem } from 'detect-browser';
import isElectron from 'is-electron';

type NewBrowser = Browser | 'whale' | 'electron';

interface NewBrowserInfo {
    readonly type: 'browser';
    readonly name: NewBrowser;
    readonly version: string;
    readonly os: OperatingSystem | null;
}

type DetectBrowserInfo = ReturnType<typeof detect> | NewBrowserInfo;
const detectBrowser = (): DetectBrowserInfo => {
    const userAgent = navigator.userAgent || '';

    const browser = detect();
    if (!browser) return browser;

    let newBrowser: DetectBrowserInfo = browser;

    // Whale 브라우저 검사
    const whaleRegExp = /Whale\/([\d.]+)/;
    const whaleMatch = userAgent.match(whaleRegExp);
    if (whaleMatch) {
        newBrowser = {
            ...newBrowser,
            name: 'whale',
            version: whaleMatch[1]
        } as DetectBrowserInfo;
    }

    // electron 검사
    if (isElectron()) {
        newBrowser = {
            ...newBrowser,
            name: 'electron',
            version: ''
        } as DetectBrowserInfo;
    }

    return newBrowser;
};

export default detectBrowser;
